import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  Link,
} from "react-router-dom";

import { Suspense, lazy, useEffect } from "react";
import "remixicon/fonts/remixicon.css";
import useSWR, { useSWRConfig } from "swr";
import { ChakraProvider } from "@chakra-ui/react";

import Layout from "components/common/Layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useAnalytics from "hooks/useAnalytics";

const OurDoctors = lazy(() => import("pages/OurDoctors"));
const Doctor = lazy(() => import("pages/Doctor"));
const Homepage = lazy(() => import("pages/Homepage"));
const Empanelment = lazy(() => import("pages/Empanelment"));
const AboutUs = lazy(() => import("pages/AboutUs"));
const Specialities = lazy(() => import("pages/Specialities"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const Academics = lazy(() => import("pages/Academics"));
const Careers = lazy(() => import("pages/Careers"));
const OpdSchedule = lazy(() => import("pages/OpdSchedule"));
const Speciality = lazy(() => import("pages/Speciality"));
const Overview = lazy(() => import("pages/about-us/Overview"));
const Mission = lazy(() => import("pages/about-us/Mission"));
const Directors = lazy(() => import("pages/about-us/Directors"));
const Accreditation = lazy(() => import("pages/Accreditation"));
const Gallery = lazy(() => import("pages/Gallery"));
const Blogs = lazy(() => import("pages/Blogs"));
const Journey = lazy(() => import("pages/about-us/Journey"));
const Blog = lazy(() => import("pages/Blog"));
const ErrorPage = lazy(() => import("pages/ErrorPage"));

import ReactGA from "react-ga4";
import { BASE_URL, HEADER_PATHNAMES, swrOptions } from "constants/constants";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { bannerAndMetadataAdded } from "./redux/bannerAndMetadataReducer";
import { fetcher, groupBy } from "constants/utils";
import LoadingScreen from "components/common/LoadingScreen";

function App() {
  const { cache, mutate } = useSWRConfig();
  const location = useLocation();

  const isProduction = process.env.NODE_ENV === "production";

  const {
    data: bannerAndMetadata,
    error,
    isLoading,
    isValidating,
  } = useSWR(`${BASE_URL}/bannersAndMetaData`, fetcher, swrOptions);

  ReactGA.initialize(process.env.REACT_APP_GA4_TRACKING_ID, {
    debug: !isProduction,
  });

  useEffect(() => {
    console.log("Location changed!", location.pathname);
  }, [location]);

  useAnalytics();

  useEffect(() => {
    if (!bannerAndMetadata?.length) return;
    // const groupByUrl1 = Object.groupBy(bannerAndMetadata, ({ url }) => url);
    const groupByUrl = groupBy(bannerAndMetadata, "url");
    store.dispatch(bannerAndMetadataAdded(groupByUrl));
    // console.log({ groupByUrl });
  }, [bannerAndMetadata]);

  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Homepage />,
  //   },
  //   {
  //     path: "/our-doctors",
  //     element: <OurDoctors />,
  //     children: [
  //       {
  //         path: "/:id",
  //         element: <Doctor />,
  //         loader: ({ request }) =>
  //           fetch("/api/dashboard.json", {
  //             signal: request.signal,
  //           }),
  //       },
  //     ],
  //   },
  // ]);

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Suspense fallback={<LoadingScreen />}>
        <ChakraProvider>
          <Layout>
            {/* <RouterProvider router={router} /> */}
            <Routes>
              <Route path="/" element={<Homepage />} exact />
              <Route
                path={`${HEADER_PATHNAMES.OUR_DOCTORS}`}
                element={<OurDoctors />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.OUR_DOCTORS}/:id`}
                element={<Doctor />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.SPECIALITY}/:id`}
                element={<Speciality />}
              />
              <Route
                path={`${HEADER_PATHNAMES.SPECIALITY}`}
                element={<Specialities />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.EMPANELMENT}`}
                element={<Empanelment />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.ABOUT_US}`}
                element={<Overview />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.MISSION}`}
                element={<Mission />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.DIRECTORS}`}
                element={<Directors />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.OUR_JOURNEY}`}
                element={<Journey />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.CONTACT_US}`}
                element={<ContactUs />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.ACADEMICS}`}
                element={<Academics />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.CAREERS}`}
                element={<Careers />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.OPD_SCHEDULE}`}
                element={<OpdSchedule />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.ACCREDITATION}`}
                element={<Accreditation />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.GALLERY}`}
                element={<Gallery />}
                exact
              />
              <Route
                path={`${HEADER_PATHNAMES.BLOGS}`}
                element={<Blogs />}
                exact
              />
              log
              <Route
                path={`${HEADER_PATHNAMES.BLOGS}/:id`}
                element={<Blog />}
                exact
              />
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
          </Layout>
        </ChakraProvider>
        {/* </PersistGate> */}
      </Suspense>
    </Provider>
  );
}

export default App;
