import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Head from "./Head";
import { useDispatch, useSelector } from "react-redux";
import { updateMetaData, resetMetaData } from "../../redux/metaDataReducer";
import { HEADER_PATHNAMES } from "constants/constants";

function Layout(props) {
  let params = useParams();
  const location = useLocation();
  const [metaData, setMetaData] = useState();
  const dispatch = useDispatch();
  const metaDataSelector = useSelector(
    ({ bannerAndMetadataReducer }) => bannerAndMetadataReducer
  );

  useEffect(() => {
    setTimeout(() => {
      window?.scrollTo({
        top: 0,
        left: 0,
        // behavior: "smooth",
      });
    }, []);
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.includes(`/${HEADER_PATHNAMES.SPECIALITY}/`) ||
      location.pathname.includes(`/${HEADER_PATHNAMES.OUR_DOCTORS}/`)
    )
      return;

    if (metaDataSelector?.[location.pathname]) {
      dispatch(
        updateMetaData({ ...metaDataSelector?.[location.pathname]?.[0] })
      );
      return;
    }

    dispatch(resetMetaData());
  }, [location, metaDataSelector]);

  return (
    <>
      <Header />
      <Head />
      <main className="flex-1 main-bg">{props.children}</main>
      <Footer />
    </>
  );
}

export default Layout;
