import React from "react";
import footer from "assets/footer.png";
import logo from "assets/FIMS.png";
import { Link } from "react-router-dom";
import PagePadding from "./PagePadding";
import { HEADER_PATHNAMES } from "constants/constants";
function Footer(props) {
  const socialMediaIconsArr = [
    {
      icon: `${process.env.PUBLIC_URL + "/assets/icons/facebook.png"}`,
      link: "https://www.facebook.com/fimshospital/",
    },
    {
      icon: `${process.env.PUBLIC_URL + "/assets/icons/instagram.png"}`,
      link: "https://www.instagram.com/fimshospital/",
    },
    {
      icon: `${process.env.PUBLIC_URL + "/assets/icons/linkedin.png"}`,
      link: "https://www.linkedin.com/company/fims-hospital/",
    },
    {
      icon: `${process.env.PUBLIC_URL + "/assets/icons/twitter.png"}`,
      link: "https://twitter.com/fimshospital?lang=en",
    },
    {
      icon: `${process.env.PUBLIC_URL + "/assets/icons/youtube.png"}`,
      link: "https://www.youtube.com/channel/UCDie9tI7_9hFRiCgYVY4KyA",
    },
  ];
  const headerLinks = [
    // "home",
    "Our Doctors",
    "Specialities",
    "opd schedule",
    "empanelment",
    "gallery",
    "Academics",
    // "awards",
    "blogs",
    "careers",
    "our journey",
    "accreditation",
    "Contact us",
  ];
  const headerLinkMap = [
    {
      pathname: HEADER_PATHNAMES.HOME,
      title: "Home",
      submenu: false,
      isHidden: true,
    },
    {
      pathname: HEADER_PATHNAMES.ABOUT_US,
      title: "About Us",
    },
    {
      pathname: HEADER_PATHNAMES.SPECIALITY,
      title: "Specialities",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.OUR_DOCTORS,
      title: "Our Doctors",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.OPD_SCHEDULE,
      title: "OPD Schedule",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.EMPANELMENT,
      title: "Empanelment",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.GALLERY,
      title: "Gallery",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.ACADEMICS,
      title: "Academics",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.BLOGS,
      title: "Blogs",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.CAREERS,
      title: "Careers",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.ACCREDITATION,
      title: "Accreditation",
      submenu: false,
    },
    {
      pathname: HEADER_PATHNAMES.CONTACT_US,
      title: "Contact Us",
      submenu: false,
    },
  ];
  return (
    <footer className="bg-[#243f66] w-full z-10 relative">
      <section className={"center flex-col bg-[#70a3d0] md:py-12 py-8"}>
        <PagePadding>
          <div className="center gap-4 ">
            <div className="portrait:h-[50px] h-[50px] aspect-square relative our-services-overlay">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/icons/chooseUs/hospital.svg"
                }
                className="h-full w-full object-contain footer-icon"
              />
            </div>
            <h1 className="text-3xl md:text-4xl font-bold text-white">FIMS</h1>
          </div>
          <div className="flex flex-wrap gap-2 justify-center mt-4">
            {headerLinkMap.map((link, i) => {
              return (
                <Link to={`${link.pathname}`} key={i}>
                  <h2
                    className="font-bold tracking-wide text-white text-center uppercase
                  "
                  >
                    {link.title} |
                  </h2>
                </Link>
              );
            })}
          </div>
        </PagePadding>
      </section>
      <PagePadding>
        <div className="grid portrait:xl:grid-cols-1 sm:grid-cols-3 landscape:sm:gap-8 w-full py-4 portrait:block center flex-col">
          <div className="rounded-2xl overflow-hidden relative landscape:aspect-square ">
            <div className="flex flex-col h-full w-full p-4 relative justify-end items-center">
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/alarm.png"}
                alt=""
                className="z-10  h-[40px] w-[40px] object-cover"
              />
              <p className="z-10 p-lg capitalize text-center text-black font-bold">
                In case of any emergency
              </p>
              <h2 className="z-10 font-bold ">
                <a href="tel:+911302205000">+91 130-2205000</a>
              </h2>
            </div>

            <div className="absolute left-0 top-0 h-full w-full  bg-white ">
              <img
                src={process.env.PUBLIC_URL + "/assets/Layer 502.png"}
                alt=""
                className="h-full w-full object-cover  absolute -top-[15%]"
              />
            </div>
          </div>
          <div className="center w-full">
            <div className="flex flex-col gap-3 sm:gap-6 text-white py-4 md:py-8">
              <div className="flex items-center gap-2 landscape:gap-8">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/icons/customer-support.svg"
                  }
                  alt=""
                  className="h-[50px] aspect-square object-contain footer-icon"
                />
                <h3 className="text-white uppercase">Get in touch</h3>
              </div>
              <div className="flex items-center gap-2 landscape:gap-8">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/placeholder.png"}
                  alt=""
                  className="h-[40px] aspect-square object-contain invert"
                />
                <h4 className="text-white font-light">
                  Sonipat – Bahalgarh Road, (Sec 4) <br />
                  Sonipat, Haryana, India
                </h4>
              </div>
              <div className="flex items-center gap-2 landscape:gap-8">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/telephone.png"}
                  alt=""
                  className="h-[40px] aspect-square object-contain invert"
                />
                <div>
                  <h4>
                    <a
                      href="tel:+9101302205000"
                      className="text-white font-light inline-block"
                    >
                      +91 - 130 - 2205000,
                    </a>
                    <a
                      href="tel:+917082012006"
                      className="text-white font-light inline-block"
                    >
                      +91 - 7082012006,
                    </a>
                  </h4>
                  <h4>
                    <a
                      href="tel:+917082012007"
                      className="text-white font-light inline-block"
                    >
                      +91 - 7082012007,
                    </a>
                    <a
                      href="tel:+917082012008"
                      className="text-white font-light inline-block"
                    >
                      +91 - 7082012008
                    </a>
                  </h4>
                </div>
              </div>
              <div className="flex items-center gap-2 landscape:gap-8">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/email.png"}
                  alt=""
                  className="h-[40px] aspect-square object-fit invert"
                />
                <h4 className="text-white font-light ">
                  <a href="mailto:info@fimssonipat.com">
                    {" "}
                    info@fimssonipat.com
                  </a>
                </h4>
              </div>
              <div className="social-media-icons center gap-4 flex-wrap">
                {socialMediaIconsArr.map((icon, i) => (
                  <div key={i} className="h-[50px] aspect-square">
                    <a href={icon.link} className="h-full w-full block">
                      <img src={icon.icon} alt="" className="h-full w-full" />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="center rounded-2xl overflow-hidden landscape:aspect-square">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13961.993106810569!2d77.0687223!3d28.9726023!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dae10afe758b9%3A0xba086fb3c026624e!2sFIMS%20Hospital!5e0!3m2!1sen!2sin!4v1692561984117!5m2!1sen!2sin"
              width="600"
              height="450"
              loading="async"
              className="h-full"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </PagePadding>
      <PagePadding styles=" bg-[#70a3d0]">
        <div className="flex justify-between  ">
          <p className="text-white md:text-lg text-sm">
            Copyright © 2023 FRANK LIFECARE PRIVATE LIMITED. All right reserved.
          </p>

          <a
            href="http://imagecurry.com/"
            target="_blank"
            className="text-white"
          >
            Developed by <b>Imagecurry</b>
          </a>
        </div>
      </PagePadding>
    </footer>
  );
}

export default Footer;
