import { HEADER_PATHNAMES } from "constants/constants";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// const defaultMetaData = {
//   meta_title: "FIMS Hospital | Best Multi Super Specialty Hospital in Sonipat",
//   meta_description:
//     "FIMS Hospital is best multi super specialty hospital in Sonipat and provide best healthcare services in Delhi NCR and near by areas.",
// };

export default function Head({ data, metaDataDB }) {
  const location = useLocation();
  // const [metaData, setMetaData] = useState(defaultMetaData);
  const metaData = useSelector(({ metaDataReducer }) => metaDataReducer);
  // const metaDataMap = {
  //   [HEADER_PATHNAMES.ABOUT_US]: {
  //     meta_title: "About FIMS Hospital | Top Best Hospital in Sonipat",
  //     meta_description:
  //       "Explore FIMS Hospital, the premier healthcare facility in Sonipat. Experience excellence in healthcare at the top best hospital in Sonipat.",
  //   },
  //   [HEADER_PATHNAMES.DIRECTORS]: {
  //     meta_title:
  //       "Meet FIMS Hospital Board of Directors: Guiding Excellence in Healthcare",
  //     meta_description:
  //       "Discover the esteemed members of FIMS Hospitals' Board of Directors, leading with expertise to ensure excellence in healthcare services and patient satisfaction.",
  //   },
  //   [HEADER_PATHNAMES.MISSION]: {
  //     meta_title: "FIMS Hospital: Vision, Mission, & Values Overview",
  //     meta_description:
  //       "Experience the difference of compassionate, patient-focused healthcare at FIMS Hospital, where our vision, mission, and values converge for your well-being.",
  //   },
  //   [HEADER_PATHNAMES.OUR_JOURNEY]: {
  //     meta_title:
  //       "The FIMS Hospital Journey | Empowering Patients with Quality Care",
  //     meta_description:
  //       "Experience the transformative FIMS Hospital Journey, where quality care meets empowerment for every patient's wellness.",
  //   },
  //   [HEADER_PATHNAMES.SPECIALITY]: {
  //     meta_title:
  //       "Discover the Specialties Offered at FIMS Hospital: Expert Healthcare Solutions",
  //     meta_description:
  //       "Explore the comprehensive range of medical specialties provided at FIMS Hospital, where personalized care meets advanced treatment options.",
  //   },
  // };

  // useEffect(() => {
  //   console.log("HEAD Location changed", location.pathname, data);
  //   let formatPathname = location.pathname?.slice(1);
  //   if (metaDataDB) {
  //     setMetaData(metaDataDB);
  //   } else if (data) {
  //     setMetaData(data);
  //   } else if (metaDataMap[formatPathname]) {
  //     setMetaData(metaDataMap[formatPathname]);
  //   } else {
  //     setMetaData(defaultMetaData);
  //   }
  // }, [data, metaDataDB]);

  useEffect(() => {
    console.log("HEAD RENDER");
  }, []);

  useEffect(() => {
    console.log("HEAD METADATA CHANGE", {
      meta_description: metaData?.meta_description,
      meta_title: metaData?.meta_title,
    });
    setTimeout(() => {
      try {
        document.title = metaData.meta_title;
        document
          .getElementsByTagName("meta")
          .namedItem("description")
          .setAttribute("content", metaData.meta_description);
      } catch (e) {
        console.log(e);
      }
    }, 0);
  }, [metaData]);

  return (
    <Helmet>
      <title>{metaData?.meta_title}</title>
      <meta name="description" content={metaData?.meta_description} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}
