import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  useEditable,
  Input,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
function CustomDrawer({
  drawerOpen,
  title,
  component,
  size,
  setDrawer,
  handleSubmit,
  showFooterButtons,
  isLoading,
  placement,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  useEffect(() => {
    if (drawerOpen) {
      onOpen();
    } else {
      handleClose();
    }
  }, [drawerOpen]);
  const handleClose = () => {
    setDrawer(false);
    onClose();
  };
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement={placement || "right"}
        onClose={handleClose}
        finalFocusRef={btnRef}
        size={size}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {title && <DrawerHeader>{title}</DrawerHeader>}
          <DrawerBody>
            {component}
            {isLoading && (
              <div className="absolute top-0 left-0 h-full w-full center">
                <div className="bg-black/50 top-0 left-0 absolute h-full w-full z-10"></div>
                <CircularProgress isIndeterminate className="z-20" />
              </div>
            )}
          </DrawerBody>
          {showFooterButtons && (
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}
export default CustomDrawer;
