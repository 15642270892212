import React from "react";
import OutsideClickDetector from "./OutsideClickDetector";

const SuggestionBox = ({
  data,
  handleClick,
  showInputSuggestions,
  setShowInputSuggestions,
  handleInternalLinkRedirect = false,
  styles,
}) => {
  if (!data) return;
  return (
    <div
      className={`absolute top-[110%] right-0 w-full max-h-[300px] overflow-y-auto rounded z-[1234] shadow-2xl ${
        styles && styles
      }`}
    >
      <div className="relative bg-white rounded overflow-hidden shadow-lg z-[1234]">
        {showInputSuggestions && (
          <OutsideClickDetector
            onOutsideClick={() => setShowInputSuggestions(false)}
          >
            {data.map((item) => (
              <div
                key={item?.id}
                className="border-b last:border-b-0 py-2 hover:bg-gray-200 cursor-pointer hover-transition px-4"
              >
                {!handleInternalLinkRedirect ? (
                  <p onClick={() => handleClick(item)} className="">
                    {item.title}
                    {item.desc ? (
                      <span className="mt-1 text-xs line-clamp-2">
                        {item.desc}
                      </span>
                    ) : null}
                  </p>
                ) : (
                  <a
                    key={item?.id}
                    href={`#${item?.id}`}
                    className="border-b last:border-b-0 py-2 hover:bg-gray-100 cursor-pointer hover-transition pl-4"
                  >
                    {item.title}
                  </a>
                )}
              </div>
            ))}
          </OutsideClickDetector>
        )}
      </div>
    </div>
  );
};

export default SuggestionBox;
