import { createSlice } from "@reduxjs/toolkit";

const initialState = [{}];
const bannerAndMetadataReducer = createSlice({
  name: "bannerAndMetadata",
  initialState: [],
  reducers: {
    bannerAndMetadataAdded(state, action) {
      console.log(action.payload);
      return action.payload
    },
  },
});
export const { bannerAndMetadataAdded } = bannerAndMetadataReducer.actions;
export default bannerAndMetadataReducer.reducer;
