import axios from "axios";
import { BANNER_IMAGES } from "constants/constants";
import LayoutSimple from "layouts/LayoutSimple";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SuggestionBox from "./SuggestionBox";
const sampleObj = [
  {
    kind: "customsearch#result",
    title: "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    htmlTitle:
      "Best <b>Heart</b>/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    link: "https://www.fimssonipat.com/speciality/cardiology",
    displayLink: "www.fimssonipat.com",
    snippet:
      "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    htmlSnippet:
      "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in&nbsp;...",
    formattedUrl: "https://www.fimssonipat.com/speciality/cardiology",
    htmlFormattedUrl: "https://www.fimssonipat.com/speciality/cardiology",
    pagemap: {
      cse_thumbnail: [
        {
          src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEAItPf11ah2c1YIHFNDNl-Lid5xh0Ggj30ZM6m6jOw4LFkSeCzMvCVYhv&s",
          width: "225",
          height: "225",
        },
      ],
      metatags: [
        {
          "msapplication-tilecolor": "#da532c",
          "theme-color": "#000000",
          viewport: "width=device-width,initial-scale=1",
          "”fragment”": "”!”",
        },
      ],
      cse_image: [
        {
          src: "https://fimssonipat.com/media/blog/1_2tQmjUwGEwtDMP9KSBwZDQ.webp",
        },
      ],
    },
  },
];
const GlobalSearch = () => {
  const navigate = useNavigate();
  let params = useParams();
  const [results, setResults] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [showInputSuggestions, setShowInputSuggestions] = useState(false);
  const initLoading = [
    {
      title: "Searching results...",
      id: 11212321312,
    },
  ];
  useEffect(() => {
    if (searchInput?.length < 3) return;
    let $globalSearchContainer = document?.getElementById(
      "global-search-container"
    );
    if (
      $globalSearchContainer &&
      window?.getComputedStyle($globalSearchContainer)?.display === "none"
    )
      return;

    if (searchInput?.length) {
      setResults(initLoading);
      setShowInputSuggestions(true);
    }

    const GOOGLE_SEARCH_API_KEY = `AIzaSyC4-r8oOAXEdSILhSMWAv8kxs3N_SNucQw`;
    const GOOGLE_SEARCH_ENGINE_ID = `375922279fb8e47a1`;
    const GOOGLE_SEARCH_URL = `https://www.googleapis.com/customsearch/v1?key=${GOOGLE_SEARCH_API_KEY}&cx=${GOOGLE_SEARCH_ENGINE_ID}&q=${searchInput}`;

    // setTimeout(() => {
    //   setResults([
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //     {
    //       title:
    //         "Best Heart/Cardiology Hospital in Sonipat, Delhi | FIMS Hospital",
    //       desc: "The Cardiac Division at FIMS has a team of experts, including clinical cardiologists, interventional cardiologists, and cardiac surgeons, who specialize in ...",
    //       link: "https://www.fimssonipat.com/speciality/cardiology",
    //     },
    //   ]);
    // }, 0);
    const getData = setTimeout(() => {
      if (!searchInput?.length) return;
      axios
        .get(GOOGLE_SEARCH_URL)
        .then((res) => res.data)
        .then((res) => {
          if (!res?.searchInformation?.totalResults) return;
          const searchRes = res?.items?.map((data, i) => ({
            title: data.title,
            desc: data.snippet,
            url: data.link,
            id: i,
          }));
          setShowInputSuggestions(true);
          setResults(searchRes);
          // console.log({ searchRes });
        })
        .catch((err) => console.log(err));
    }, 3000);

    return () => clearTimeout(getData);
  }, [searchInput]);

  return (
    <div className="relative max-w-[400px]">
      <div className="flex items-center relative z-[1000] justify-center portrait:my-2">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/search.png`}
          alt="search-icon"
          className="object-cover h-[20px] opacity-50 aspect-square bg-white absolute top-1/2 translate-y-[-50%] left-[10px]"
        />
        <input
          type="text"
          className="border border-gray-200 py-2 px-4 ps-[40px] rounded-lg focus:outline-none "
          value={searchInput}
          placeholder="Search FIMS.."
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <SuggestionBox
          setShowInputSuggestions={setShowInputSuggestions}
          showInputSuggestions={showInputSuggestions}
          handleClick={(item) => {
            let url = item.url.replace("https://www.fimssonipat.com", "");
            url = url.replace("https://fimssonipat.com", "");
            setShowInputSuggestions(false);
            setSearchInput("");
            navigate(url);
          }}
          data={results}
          styles={"w-[400px] "}
        />
      </div>
    </div>
  );
};

export default GlobalSearch;
