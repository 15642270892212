export const humanizeIfNumber = (num) => {
  if (!num) return;
  return Number(num).toLocaleString("en-IN");
};
export function divideArray(arr, y) {
  if (y <= 0) {
    console.error("Number of parts should be greater than 0");
  }

  const chunkSize = Math.ceil(arr.length / y);
  const chunks = [];
  let i = 0;

  while (i < arr.length) {
    chunks.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }

  return chunks;
}
export function removeSpecialCharacters(inputString) {
  // Define a regular expression pattern to match special characters
  const pattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g;

  // Use the `replace` method to replace special characters with an empty string
  const result = inputString.replace(pattern, "");

  return result?.trim()?.toLowerCase() || "";
}
export const fetcher = (...args) => {
  return fetch(...args).then(async (res) => {
    let payload;
    try {
      if (res.status === 204) return null; // 204 does not have body
      payload = await res.json();
      if (res.ok) {
        let filteredRes =
          payload?.length && payload?.filter((p) => p.is_active);
        return filteredRes?.length ? sortByPriority(filteredRes) : payload;
      } else {
        return Promise.reject(
          payload.error || new Error("Something went wrong")
        );
      }
    } catch (e) {
      console.error("Something went wrong", e);
    }
  });
};
export const getYouTubeEmbedLink = (url) => {
  // Extract video ID from URL
  const videoIdMatch = url.match(/[?&]v=([^&]+)/);
  const videoId = videoIdMatch ? videoIdMatch[1] : null;
  // https://www.youtube.com/watch?v=gBENk-Wo_6w&ab_channel=FIMSHospital
  // Construct embed link
  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    return "https://www.youtube.com/embed/CgeFWvbrjSU";
  }
};
export const groupBy = (arr, grpBy) => {
  if (!arr.length) {
    return;
  }
  if (!grpBy) {
    return;
  }
  return arr.reduce((group, product) => {
    const category = product[grpBy];
    group[category] = group[category] ?? [];
    group[category].push(product);
    return group;
  }, {});
};

export const stringSort = (
  arr = [],
  sorter,
  sortByPriority = false,
  direction = "asc",
  checkActive = false
) => {
  if (!arr?.length) return arr;
  if (!sorter?.length) return arr;
  try {
    let result = arr.sort((a, b) => {
      let s = sorter.split(".");
      let [nameA, nameB] = [a, b].map((val) => {
        return s.reduce((prevValue, currentValue) => {
          if (prevValue) {
            return prevValue[currentValue];
          }
          return val[currentValue];
        }, "");
      });

      if ((direction = "asc")) {
        return removeSpecialCharacters(nameA).localeCompare(
          removeSpecialCharacters(nameB)
        );
      }
      return removeSpecialCharacters(nameB).localeCompare(
        removeSpecialCharacters(nameA)
      );
    });
    let sortedArray = sortByPriority
      ? result.sort((a, b) => b.priority - a.priority)
      : result;
    if (sortedArray?.length && checkActive) {
      sortedArray = sortedArray.filter((ele) => ele.is_active);
    }
    return sortedArray;
  } catch (e) {
    console.error("Error in string Sort", e);
    return arr;
  }
};
export function formatDate(inputDate, showYear = true) {
  const date = new Date(inputDate);

  const options = { month: "short", day: "numeric" };
  if (showYear) {
    options["year"] = "numeric";
  }
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
}

export const sortByPriority = (data) => {
  if (!data.length) return data;
  try {
    return data
      ?.filter((d) => d.is_active)
      ?.sort((a, b) => a.priority - b.priority);
  } catch (e) {
    console.error("Sort by priority error", e);
    return data;
  }
};

export function hasAllFields(obj, requiredFields) {
  for (const field of requiredFields) {
    if (!(field in obj)) {
      return false;
    }
  }
  return true;
}

export function shuffleArrays(arr1, arr2) {
  const maxLength = Math.max(arr1.length, arr2.length);

  for (let i = maxLength - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements within each array if the index is within its length
    if (i < arr1.length && j < arr1.length) {
      [arr1[i], arr1[j]] = [arr1[j], arr1[i]];
    }

    if (i < arr2.length && j < arr2.length) {
      [arr2[i], arr2[j]] = [arr2[j], arr2[i]];
    }
  }
}

export function areObjectValuesNotEmpty(obj) {
  let missingFieldsArray = [];
  for (const key of Object.keys(obj)) {
    if (!obj[key]) {
      missingFieldsArray.push(obj[key]);
    }
  }
  if (missingFieldsArray.length) {
    return false;
  }
  return true;
}
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export function isValidPhoneNumber(phoneNumber) {
  // The following regular expression matches a basic US phone number format.
  const phoneRegex = /^\d{10}$/;

  return phoneRegex.test(phoneNumber);
}

export const getDoctorName = (doctor) => {
  return `${doctor.id}&name=${doctor?.user?.full_name}` || null;
};

export const getRedirectUrlId = (data, type) => {
  if (!data) return;
  switch (type) {
    case "doctor":
      return data.url;
    case "service":
      return data.url;
    default:
      return "";
  }
};
