import React from "react";
import logo from "assets/FIMS.png";

const LoadingScreen = () => {
  return (
    <div className="h-screen w-screen overflow-hidden relative z-[10000] bg-white">
      <div className="flex justify-center items-center flex-col w-full  h-full">
        <img src={logo} alt="" className="object-contain" />
        <p className="text-gray-400">Loading</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
