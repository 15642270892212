import React from "react";

function PagePadding({ children, styles, ...rest }) {
  return (
    <section className={`page-padding ${styles ? styles : ""}`} {...rest}>
      {children}
    </section>
  );
}

export default PagePadding;
