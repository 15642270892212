import { combineReducers, configureStore } from "@reduxjs/toolkit";
import bannerAndMetadataReducer from "./bannerAndMetadataReducer";
import metaDataReducer from "./metaDataReducer";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  bannerAndMetadataReducer,
  metaDataReducer,
});
const persistConfig = {
  key: "fims",
  storage,
};
const persistReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

store.subscribe((data) => console.log("Store update: ", store.getState()));
