import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meta_title: "FIMS Hospital | Best Multi Super Specialty Hospital in Sonipat",
  meta_desc:
    "FIMS Hospital is best multi super specialty hospital in Sonipat and provide best healthcare services in Delhi NCR and near by areas.",
};

const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {
    updateMetaData(state, action) {
      return action.payload;
    },
    resetMetaData(state, action) {
      return initialState;
    },
  },
});
export const { updateMetaData, resetMetaData } = metaDataSlice.actions;
export default metaDataSlice.reducer;
