import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useBannerData = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  const bannerAndMetadata = useSelector(
    ({ bannerAndMetadataReducer }) => bannerAndMetadataReducer
  );
  let formatPathname = location.pathname;

  useEffect(() => {
    if (bannerAndMetadata?.[formatPathname]) {
      setData(bannerAndMetadata?.[formatPathname]?.[0]);
    }
  }, [bannerAndMetadata]);

  return {
    banner_image_alt: data?.banner_image_alt,
    banner_image: data?.banner_image,
    banner_title: data?.banner_title,
  };
};

export default useBannerData;
