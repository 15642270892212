import React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function useAnalytics(props) {
  const location = useLocation();
  const isProduction = process.env.NODE_ENV === "production";

  React.useEffect(() => {
    const path = location.pathname + location.search;
    ReactGA.send({
      hitType: "pageview",
      page: path,
    });
  }, [location]);
}

export default useAnalytics;
