import PagePadding from "components/common/PagePadding";
import useBannerData from "hooks/useBannerData";
import React, { useEffect, useState } from "react";

function LayoutSimple({ bg, title, description, children, styles }) {
  const bannerImageData = useBannerData();

  return (
    <>
      <section className="overflow-hidden w-full relative h-[40vh] sm:h-[55vh] xl:h-[65vh] flex flex-col justify-between ">
        <div className="h-full w-full opacity-100 absolute top-0 left-0 bg-gradient-to-r from-black/50 to-transparent"></div>
        <div className="absolute h-full top-0 left-0 w-full -z-10">
          <img
            className="object-cover h-full w-full"
            src={bannerImageData?.banner_image || bg}
            alt={bannerImageData?.banner_image_alt || `banner image`}
          />
        </div>
        <div className="flex-col flex justify-center h-full w-full z-10">
          <PagePadding>
            <div className="xl:w-[70%]">
              <h1 className="font-extrabold text-white underline-offset-8 underline decoration-secondary decoration-[5px] md:decoration-[10px] tracking-wider portrait:sm:text-5xl text-3xl lg:text-4xl xl:text-6xl 2xl:text-7xl 2xl:leading-normal leading-relaxed">
                {bannerImageData?.banner_title || title}
              </h1>
              <p className="xl:mt-4 mt-2 text-sm md:text-base xl:text-lg text-white">
                {bannerImageData?.banner_description}
              </p>
            </div>
          </PagePadding>
        </div>
      </section>
      <PagePadding styles={styles}>{children}</PagePadding>
    </>
  );
}

export default LayoutSimple;
